import React, { useState } from 'react'
import './Footer.css'
import { Link } from "react-router-dom";

const Footer = () => {

  const [menu, setMenu] = useState("teknobase");

  return (
    <div className="footer">
            <div className="row footer-icons">
                <ul>
                    <li>
                        <a title="twitter" href="https://twitter.com/Tekno_Base">
                            <i className="fa-brands fa-x-twitter"></i>
                        </a>
                    </li>
                    <li>
                        <a title="youtube" href="https://www.youtube.com/channel/UCcW1m2j_mMb0u5lb84iiAlA">
                            <i className="fa-brands fa-youtube"></i>
                        </a>
                    </li>
                    <li>
                        <a title="instagram" href="https://www.instagram.com/teknobase_/">
                            <i class="fa-brands fa-instagram"></i>
                        </a>
                    </li>
                </ul>
            </div>
        
            <div className="row footer-links">
                <ul>
                    <li onClick = {()=>{setMenu("web")}} className="nav-item">
                        <Link to='/'>
                            Anasayfa
                        </Link>
                    </li>
                    <li>
                        <Link to='/kurumsal'>
                            Kurumsal
                        </Link>
                    </li>
                    <li>
                        <Link to='/referans'>
                            Referans
                        </Link>
                    </li>
                    <li>
                        <Link to='/iletisim'>
                            İletişim
                        </Link>
                    </li>
                </ul>
            </div>
        
            <div className="row footer-text">
                <p>Copyright © 2023 All rights reserved Designed By: EFE DOGAN</p>
            </div>
      </div>
  )
}

export default Footer
