import React from 'react'
import './CSS/Referances.css'
import ws1 from "../Components/Assets/img/ws1.webp"
import ws2 from "../Components/Assets/img/ws2.webp"
import ws3 from "../Components/Assets/img/ws3.webp"
import ws4 from "../Components/Assets/img/ws4.webp"
import ws5 from "../Components/Assets/img/ws5.webp"
import ws6 from "../Components/Assets/img/ws6.webp"
import ws7 from "../Components/Assets/img/ws7.webp"
import ws8 from "../Components/Assets/img/ws8.webp"



const Referances = () => {
  return (
    <div className='referances'>
        {/* Row */}
        <div className="referance_row">
          <div className="text-center py-2 text-white">
              <div className="container">
                  <h1 className="section-title my-4">TEKNOBASE</h1>
                  <div className="border-bottom"></div>
                  <p style={{fontSize: 20}}>Siz hayal edin biz oluşturalım. Yaptıklarımız yapacaklarımızın teminatıdır.</p>
              </div>
          </div>
        </div>
            
        {/* Projects Card */}
        <section id='project_card'  className="my-5">
          <div className="container">
            {/* Row 1 */}
            <div className="row">
              <div className="col-12 col-md-6 col-lg-4">
                <div className="card m-2">
                  <div className="box">
                    <img alt="gymburn" src={ws1} className="card-img-top"/>
                    <div className="box-content">
                        <h3 className="title">GYMBURN</h3>
                        <span className="post">Published</span>
                        <ul className="icon">
                            <li><a href="https://www.youtube.com/channel/UCcW1m2j_mMb0u5lb84iiAlA"><i className="fa fa-search"></i></a></li>
                            <li><a href="https://gymburn.com.tr/"><i className="fa fa-link"></i></a></li>
                        </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div className="card m-2">
                  <div className="box">
                    <img alt="Deluxe Hotel" src={ws2} className="card-img-top"/>
                    <div className="box-content">
                        <h3 className="title">Deluxe Hotel</h3>
                        <span className="post">Draft</span>
                        <ul className="icon">
                            <li><a href="https://www.youtube.com/channel/UCcW1m2j_mMb0u5lb84iiAlA"><i className="fa fa-search"></i></a></li>
                            <li><a href="https://github.com/EfeCemDogan"><i className="fa fa-link"></i></a></li>
                        </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div className="card m-2">
                  <div className="box">
                    <img alt="Istanbul University" src={ws3} className="card-img-top"/>
                    <div className="box-content">
                        <h3 className="title">Istanbul University</h3>
                        <span className="post">Draft</span>
                        <ul className="icon">
                            <li><a href="https://www.youtube.com/channel/UCcW1m2j_mMb0u5lb84iiAlA"><i className="fa fa-search"></i></a></li>
                            <li><a href="https://github.com/EfeCemDogan"><i className="fa fa-link"></i></a></li>
                        </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* Row 2 */}
              <div className="col-12 col-md-6 col-lg-4">
                <div className="card m-2">
                  <div className="box">
                    <img alt="News Site" src={ws4} className="card-img-top"/>
                    <div className="box-content">
                        <h3 className="title">News Site</h3>
                        <span className="post">Draft</span>
                        <ul className="icon">
                            <li><a href="https://www.youtube.com/channel/UCcW1m2j_mMb0u5lb84iiAlA"><i className="fa fa-search"></i></a></li>
                            <li><a href="https://github.com/EfeCemDogan"><i className="fa fa-link"></i></a></li>
                        </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div className="card m-2">
                  <div className="box">
                    <img alt="Dentist Website" src={ws5} className="card-img-top"/>
                    <div className="box-content">
                        <h3 className="title">Dentist Website</h3>
                        <span className="post">Under Construction</span>
                        <ul className="icon">
                            <li><a href="https://www.youtube.com/channel/UCcW1m2j_mMb0u5lb84iiAlA"><i className="fa fa-search"></i></a></li>
                            <li><a href="https://github.com/EfeCemDogan"><i className="fa fa-link"></i></a></li>
                        </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div className="card m-2">
                  <div className="box">
                    <img alt="Personal Website" src={ws6} className="card-img-top"/>
                    <div className="box-content">
                        <h3 className="title">Personal Website</h3>
                        <span className="post">Draft</span>
                        <ul className="icon">
                            <li><a href="https://www.youtube.com/channel/UCcW1m2j_mMb0u5lb84iiAlA"><i className="fa fa-search"></i></a></li>
                            <li><a href="https://github.com/EfeCemDogan"><i className="fa fa-link"></i></a></li>
                        </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* Row 3 */}
              <div className="col-12 col-md-6 col-lg-4">
                <div className="card m-2">
                  <div className="box">
                    <img alt="TiltHaber" src={ws7} className="card-img-top"/>
                    <div className="box-content">
                        <h3 className="title">TiltHaber</h3>
                        <span className="post">Under Construction</span>
                        <ul className="icon">
                            <li><a href="https://www.youtube.com/channel/UCcW1m2j_mMb0u5lb84iiAlA"><i className="fa fa-search"></i></a></li>
                            <li><a href="https://github.com/EfeCemDogan"><i className="fa fa-link"></i></a></li>
                        </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div className="card m-2">
                  <div className="box">
                    <img alt="E-Shop" src={ws8} className="card-img-top"/>
                    <div className="box-content">
                        <h3 className="title">E-Shop</h3>
                        <span className="post">Draft</span>
                        <ul className="icon">
                            <li><a href="https://www.youtube.com/channel/UCcW1m2j_mMb0u5lb84iiAlA"><i className="fa fa-search"></i></a></li>
                            <li><a href="https://github.com/EfeCemDogan"><i className="fa fa-link"></i></a></li>
                        </ul>
                    </div>
                  </div>
                </div>
            </div>
              
            </div>
          </div>
        </section>
    </div>
  )
}

export default Referances
