// app.js
import './App.css';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import TeknoBase from './Pages/TeknoBase';
import Contact from './Pages/Contact';
import Referances from './Pages/Referances';
import About from './Pages/About';
import ErrorPage from './Pages/ErrorPage';

import Footer from './Components/Footer/Footer';
import Navbar from './Components/Navbar/Navbar';
import SocialMediaIcons from './Components/SocialMediaIcons/SocialMediaIcons';
import ScrollButton from './Components/ScrollButton/ScrollButton.jsx';
import BackgroundVideo from './Components/BackgroundVideo/BackgroundVideo.jsx';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <SocialMediaIcons />
        <Routes>
          <Route path='/' element={<TeknoBase />} />
          <Route path='/kurumsal' element={<About />} />
          <Route path='/referans' element={<Referances />} />
          <Route path='/iletisim' element={<Contact />} />
          <Route path='*' element={<ErrorPage />} />
        </Routes>
        <ScrollButton />
        <Footer />
        <BackgroundVideo />
      </Router>
    </div>
  );
}

export default App;
