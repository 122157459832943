import React from 'react'
import './Navbar.css'
import { Link } from "react-router-dom";
import logo from "../Assets/img/logoTekno.png"



const Navbar = () => {
  

  return (
    <div>
      <nav className="navbar container navbar-expand-lg border-body" data-bs-theme="dark">
        <div className="container-fluid">
          {/* Sol tarafta ikon */}
          <Link className='navbar-brand' to='/'>
            <img src={logo} alt="" className="main-nav__logo" />
          </Link>
          
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Sağ tarafta linkler */}
          <div className="collapse navbar-collapse" id="navbarColor01">
            <ul className="navbar-nav dropdown-menu-right text-end ms-auto mb-2 mb-lg-0">
              <li className="nav-item dropdown-item">
                <Link to='/' className={`nav-link`}>
                  ANASAYFA
                </Link>
              </li>
              <li className="nav-item dropdown-item">
                <Link to='/kurumsal' className={`nav-link`} >
                  Kurumsal
                </Link>
              </li>
              <li className="nav-item dropdown-item">
                <Link to='/referans' className={`nav-link`}>
                  Referans
                </Link>
              </li>
              <li className="nav-item dropdown-item">
                <Link to='/iletisim' className={`nav-link`}>
                  İletişim
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>

    
  )
}

export default Navbar