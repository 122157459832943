import React from 'react'
import './CSS/About.css'
import a1 from '../Components/Assets/img/a1.webp'

const About = () => {
  return (
    <div>
        {/* Row */}
        <div className="referance_row mb-1">
          <div className="text-center py-2 text-white">
              <div className="container">
                  <h1 className="section-title my-4">TEKNOBASE</h1>
                  <div className="border-bottom"></div>
                  <h2 className='h5'>TEKNOBASE THE PINNACLE OF TECHNOLOGY AND GAMING</h2>
              </div>
          </div>
        </div>
        
        {/* Text */}
        <div className='mt-5'>
          <div className="container">
            <div className="text-white text-center">
              <p className='mb-5'>
                Dijital dünyada sizi öne çıkaran çözümler sunan bir yazılım şirketidir. Biz, modern ve etkileyici web siteleri inşa ederek, markanızın dijital varlığını güçlendirmenize yardımcı oluyoruz.
              </p>
              <p className='m-0 p-0 h5'>
                Web Tasarımı ve Geliştirme
              </p>
              <p className='mb-5'>
                Biz, her türlü cihaza uygun, estetik ve işlevsel web siteleri tasarlamak için buradayız. Kullanıcı dostu arayüzler ve en son teknolojiyi kullanarak, markanızın çevrimiçi varlığını güçlendiriyoruz.
              </p>

              <p className='m-0 p-0 h5'>
              Neden Bizi Seçmelisiniz?
              </p>
              <p>
                Deneyimli ve Uzman Ekibimiz: Profesyonel tasarımcılar ve geliştiricilerden oluşan ekibimiz, projelerinizi en iyi şekilde hayata geçirmek için burada.
              </p>
              <p>
                Responsive Tasarım: Web sitelerimiz, masaüstü bilgisayarlar, tabletler ve mobil cihazlar gibi her türlü ekran boyutunda sorunsuz bir deneyim sunar.
              </p>
              <p className='mb-5'>
                İleri Teknoloji Kullanımı: En son teknolojileri takip ediyor ve projelerimizde kullanarak, müşterilerimize rekabet avantajı sağlıyoruz.
              </p>
              <p className='m-0 p-0 h5'>
                Nasıl Çalışırız?
              </p>
              <p>
                İhtiyaç Analizi: İlk olarak, ihtiyaçlarınızı anlamak için detaylı bir analiz yaparız.
              </p>
              <p>
                Stratejik Planlama: Belirlenen hedeflere ulaşmak için stratejik bir plan oluştururuz.
              </p>
              <p>
                Tasarım ve Geliştirme: Profesyonel ekibimiz, benzersiz ve işlevsel bir web sitesi tasarlar ve geliştirir.
              </p>
              <p>
                Test ve Onay: Web sitesini detaylı bir şekilde test ederiz ve sizin onayınızı alırız.
              </p>
              <p>
                Lansman ve Destek: Web sitenizi yayına alır ve ihtiyaçlarınıza göre sürekli destek sağlarız.
              </p>
            </div>
          </div>
        </div>

        {/* Atatürk */}
        <div className="container about-img mt-5">
          <img src={a1} className="d-block w-100" alt="..." />
        </div>
    </div>
  )
}

export default About