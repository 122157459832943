import React from 'react';
import './ScrollButton.css';
import { topFunction } from './ScrollButton.js'; // topFunction'ı doğru şekilde import et

const ScrollButton = () => {
  return (
    <div>
        <i onClick={topFunction} id="myBtn" title="Go to top" class="fa-solid fa-chevron-up">  </i>
    </div>
  );
};

export default ScrollButton;
