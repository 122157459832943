import React from 'react'
import './BackgroundVideo.css'
import intro from "../Assets/img/intro.webm"


export const BackgroundVideo = () => {
  return (
    <div>
        {/* Background Video */}
        <div className="background-video">
            <video autoPlay loop muted>
                <source src={intro} type="video/webm" />
                Your browser does not support the video tag.
            </video>
        </div>
    </div>
  )
}

export default BackgroundVideo
