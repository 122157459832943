import React from 'react'
import './CSS/TeknoBase.css'
import { Link } from 'react-router-dom'

// Bussines Img
import b1 from "../Components/Assets/img/b1.webp"
import b2 from "../Components/Assets/img/b2.webp"
import b3 from "../Components/Assets/img/b3.webp"
import b4 from "../Components/Assets/img/b4.webp"

// Gif
import gif1 from "../Components/Assets/img/gif1.webm"
import gif4 from "../Components/Assets/img/gif4.webm"

// Works Img
import work1 from "../Components/Assets/img/work-1.webp"
import work2 from "../Components/Assets/img/work-2.webp"
import work3 from "../Components/Assets/img/work-3.webp"
import work4 from "../Components/Assets/img/work-4.webp"
import work5 from "../Components/Assets/img/work-5.webp"

// Social Media Img
import n1 from "../Components/Assets/img/n1.webp"
import n2 from "../Components/Assets/img/n2.webp"
import n3 from "../Components/Assets/img/n3.webp"
import s1 from "../Components/Assets/img/s2.webp"







const TeknoBase = () => {
  return (
    <div>
      {/* Header */}
      <div className="header">
        <div className="section_info_a ">
          <div className="my-5">
            <div className="container p-0">
                <main>
                    <div className="row">
                        {/* Header Text */}
                        <aside className="col-xl-6 col-lg-6 col-md-6">
                          <div className="content">
                            <h1>TEKNOBASE</h1>
                            <p className='h4'>İnternette var olabilmek için ihtiyacınız olan her şey</p>
                            <p>İster yeni başlıyor olun ister köklü bir marka olun, güçlü platformumuz işletmenizin büyümesine yardımcı olur. Siz hayal edin biz yapalım.</p>
                            <div className="box">
                              <Link to='/iletisim'>
                                İLETİŞİME GEÇ
                              </Link>
                            </div>
                          </div>
                        </aside>
                        {/* GIF */}
                        <div className="col-xl-6 col-lg-6 col-md-6 ">
                          <div className=''>
                            <video  className="section_gif card" autoPlay loop muted src={gif1} type="video/webm" />
                          </div>
                        </div>
                    </div>
                </main>
            </div>
          </div>
        </div>
      </div>

      {/* Row */}
      <div className="section_row_a d-block">
        <div className="text-center py-2 text-white">
          <div className="container">
            <p className="section-title my-4 h2">HİZMETLERİMİZ</p>
            <div className="border-bottom"></div>
            <p style={{fontSize: 20}}>Profesyonel ekibimiz markanızı güçlendirmenize ve işinizi büyütmenize olanak tanır. Teknolojide geri kalmayın.</p>
          </div>
        </div>
      </div>

      {/* Cards */}
      <div className='services_cards mb-5'>
      <div className="container mt-4">
        <div className="row text-center">
          <div className="col-sm-6 col-md-4 mb-4">
            <div className="card">
              <div className="card-body">
                <div className='d-block'>
                  <h3 className="card-title">Web Yazılım</h3>
                  <p className="card-text">Web Yazılım Hizmetleri işinizi dijitalleştirmede önemli bir rol oynar.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 mb-4">
            <div className="card">
              <div className="card-body">
                <div className="d-block">
                  <h3 className="card-title">Web Tasarım</h3>
                  <p className="card-text">Modern ve etkileyici web tasarım hizmetlerinden yararlanın.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 mb-4">
            <div className="card">
              <div className="card-body">
                <div className='d-block'>
                  <h3 className="card-title">SEO Hizmetleri</h3>
                  <p className="card-text">Web sitenizin görünürlüğünü artırarak, arama motorlarında üst sıralara çıkın.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 mb-4">
            <div className="card">
              <div className="card-body">
                <div className="d-block">
                  <h3 className="card-title">Web Site Bakım Hizmetleri</h3>
                  <p className="card-text">İşletmenizin çevrimiçi varlığını sürekli güncel ve güvenli halde tutun.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 mb-4">
            <div className="card">
              <div className="card-body">
                <div className="d-block">
                  <h3 className="card-title">Sosyal Medya Yönetimi</h3>
                  <p className="card-text">İnsanlarla bağlantı kurmanın gücünü keşfetin.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 mb-4">
            <div className="card">
              <div className="card-body">
                <div className="d-block">
                  <h3 className="card-title">Grafik Tasarım</h3>
                  <p className="card-text">Markanızı görsel olarak güçlendirin.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>

      {/* Row */}
      <div className="section_row_b">
        <div className="text-center text-white">
          <div className="container">
            <p className="section-title mt-2 h2">Her Cihaza Uygun</p>
            <div className="border-bottom"></div>
          </div>
        </div>
      </div>

      {/* project b */}
      <div className="section_info_b">
        <div className=" slider">
            <div className="container">
                <main>
                  <div className="row">
                    {/* GIF */}
                    <div className="col-lg-6">
                      <div className=''>
                      <video  className="section_gif card" autoPlay loop muted src={gif4} type="video/webm" />
                      </div>
                    </div>
                    {/* Header Text */}
                    <aside className="d-lg-block col-lg-6">
                      <div className="content">
                        <h2>TEKNOBASE</h2>
                        <p className='h4'>Bütün cihazlarda tam erişim.</p>
                        <p>Web siteniz veya uygulamanız her cihazda kusursuz bir şekilde çaşmasını sağlıyoruz. Tasarım ve dizayndan ödün vermeden en akıcı şekilde markanızı oluşturuyoruz. </p>
                        <div className="box">
                          <Link to='/iletisim'>
                            İLETİŞİME GEÇ
                          </Link>
                        </div>
                      </div>
                    </aside>
                  </div>
                </main>
            </div>
        </div>
      </div>

      {/* Bussiness */}
      <div className="bussiness">
        <div className="card-group container">
          <div className="card col-xs-6 col-sm-3">
            <img className="card-img-top" src={b3} alt="Card image cap"/>
          </div>
          <div className="card col-xs-6 col-sm-3">
            <img className="card-img-top" src={b4} alt="Card image cap"/>
          </div>
          <div className="card col-xs-6 col-sm-3">
            <img className="card-img-top" src={b2} alt="Card image cap"/>
          </div>
          <div className="card col-xs-6 col-sm-3">
            <img className="card-img-top" src={b1} alt="Card image cap"/>
          </div>
        </div>
      </div>

      {/* img Carousel */}
      <section id="carousel-img">
        <div className="container"> 
          <div className="items">
              <div className="entry">
                <img src={work1} alt="Smiling person" />
              </div>
              <div className="entry">
                <img src={work2} alt="Smiling person" />
              </div>
              <div className="entry">
                <img src={work3} alt="Smiling person" />
              </div>
              <div className="entry">
                <img src={work4} alt="Smiling person" />
              </div>
              <div className="entry">
                <img src={work5} alt="Smiling person" />
              </div>
            </div>
        </div>
      </section> 

      {/* Row */}
      <div className="section_row_c">
        <div className="text-center py-2 text-white">
          <div className="container">
            <h2 className="section-title my-4">En Güncel Haberler İçin Bizi Takip Edin</h2>
            <div className="border-bottom"></div>
            <p style={{fontSize: 20}}>Teknolojide geri kalmamak ve en güncel bilgilere ulaşabilmek için bizi sosyal medya üzerinden takip edebilirsiniz.</p>
          </div>
        </div>
      </div>

      {/* project b */}
      <div className="section_info_c">
        <div className="pt-3 slider">
          <div className="container">
            <main className='card d-flex'>
              <div className="row p-4">
                {/* GIF */}
                <div className="col-lg-6 d-none d-lg-block">
                  <img className="card-img-top d-block" src={s1} alt="" />
                </div>
                {/* News */}
                <aside className=" col-lg-6">
                  <div className="content d-flex flex-column justify-content-between h-100">
                    {/* News 1 */}
                    <div className="card news1">
                      <a href="https://twitter.com/Tekno_Base/status/1744661674856972510" className="news_link">
                        <div className="row g-0">
                          <div className="col-md-4">
                            <img src={n1} className="img-fluid rounded-start" alt="..." />
                          </div>
                          <div className="col-md-8">
                            <div className="card-body">
                              <h5 className="card-title">Twitter:Tekno_Base</h5>
                              <p className="card-text">LG, Varlığı Yokluğu Bir Olan Şeffaf Televizyonunu Duyurdu: Karşınızda OLED T!</p>
                              <p className="card-text"><small className="text-body-secondary">5 dakika önce paylaşıldı</small></p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    {/* News 2 */}
                    <div className="card news2">
                      <a href="https://twitter.com/Tekno_Base/status/1744081070805418473" className="news_link">
                        <div className="row g-0">
                          <div className="col-md-4">
                            <img src={n2} className="img-fluid rounded-start" alt="..." />
                          </div>
                          <div className="col-md-8">
                            <div className="card-body">
                              <h5 className="card-title">Twitter:Tekno_Base</h5>
                              <p className="card-text">Insomniac’ın başı dertte: Marvel’s Spider-Man 2’nin PC portu için çalışmalar başladı </p>
                              <p className="card-text"><small className="text-body-secondary">1 saat önce paylaşıldı</small></p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    {/* News 3 */}
                    <div className="card news3">
                    <a className='news_link' href="https://twitter.com/Tekno_Base/status/1744665726944731164">
                        <div className="row g-0">
                          <div className="col-md-4">
                            <img src={n3} className="img-fluid rounded-start" alt="..." />
                          </div>
                          <div className="col-md-8">
                            <div className="card-body">
                              <h5 className="card-title">Twitter:Tekno_Base</h5>
                              <p className="card-text">iPhone'ları yavaşlatmıştı: Apple kullanıcılara bu ay ödeme yapacak</p>
                              <p className="card-text"><small className="text-body-secondary">3 saat önce paylaşıldı</small></p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </aside>
              </div>
            </main>
          </div>
        </div>
      </div>

    </div>
  )
}


export default TeknoBase