import React from 'react'
import './SocialMediaIcons.css'

const SocialMediaIcons = () => {
  return (
    <section className="social-icons">
    <nav>
        <ul>
            <li className="twitter-list">
                <a title="twitter" href="https://twitter.com/Tekno_Base">
                <i className="fa-brands fa-x-twitter"></i>
                Twitter 
                </a>
            </li>
            <li className="youtube-list">
                <a title="youtube" href="https://www.youtube.com/channel/UCcW1m2j_mMb0u5lb84iiAlA">
                  <i className="fa-brands fa-youtube"></i>
                Youtube 
                </a>
            </li>
            <li className="instagram-list">
                <a title="instagram" href="https://www.instagram.com/teknobase_/">
                  <i class="fa-brands fa-instagram"></i>
                Instagram
                </a>
            </li>
        </ul>
    </nav>
  </section>
  )
}

export default SocialMediaIcons
