import React from 'react'
import './CSS/Contact.css'

const Contact = () => {
  return (
    <div>
      <section id="contact" className="text-center py-5">
        <div className="container">
          <div className="container py-4">
            <h2 className="section-title h2">İLETİŞİME GEÇ</h2>
            <div className="border-bottom py-2 mb-3"></div>
            <h1 className='d-none'>TEKNOBASE</h1>
            <form 
              action="https://formspree.io/f/xyyqbboa"
              method="POST">
              <div className="form-fields">
                <input
                  type="text"
                  className="name input"
                  name="fullname"
                  id="fullname"
                  placeholder="İsim"
                />
                <input
                  type="text"
                  className="subject input"
                  name="subject"
                  placeholder="Konu"
                  id="subject"
                />
                <input
                  type="email"
                  className="email input"
                  name="email"
                  id="email"
                  placeholder="Email"
                />
                <input
                  type="tel"
                  className="phone input"
                  name="phone"
                  id="phone"
                  placeholder="Telefon"
                />
                <textarea
                  name="message input"
                  className="message"
                  id="message"
                  placeholder="   Mesajınızı Giriniz"
                ></textarea>
                <div data-netlify-recaptcha="true"></div>
                <button type="submit" className="btn btn-dark">GÖNDER</button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Contact
